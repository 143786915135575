import React, { useState, useEffect } from 'react';
import Geocode from 'react-geocode';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { API_URL_FOUR } from "../../../config/config";
import { getShipping } from '../../../services/authServices';
import { formatMoney, userFullAddress } from '../../../helpers/utils';
import { addShippingOption, shippingOptionLoader } from '../../../redux/actions/authActions';

Geocode.setApiKey('AIzaSyCs6mzbRW8QYOzNkPGWG9ghCYfOCT5vOIc');
Geocode.setLanguage('en');
Geocode.setRegion('es');
Geocode.setLocationType('ROOFTOP');
Geocode.enableDebug();

function Shipping(props) {
   const { setShippingOptionSelect, isShipDiffrentAddress, setIsShipDiffrentAddress, handleChange, payWithNewCard, shippingTotal,
      loginType } = props;
   const dispatch = useDispatch();
   const shippingOptions = useSelector((state) => state.auth.shippingOptions);
   const userInfo = useSelector((state) => state.auth.userInfo);
   const userShippingAddress = useSelector((state) => state.auth.userShippingAddress);
   const userShippingAddressList = useSelector((state) => state.auth.userShippingAddressList);
   const cartProductsReducer = useSelector((state) => state.auth.products);
   const cart = useSelector((state) => state.auth.cart);
   const cartItemId = useSelector((state) => state.auth.cart?.cartItemId);
   const merchantUrlData = useSelector((state) => state.auth.merchantUrlData);
   const userAddress = useSelector((state) => state.auth.userAddress);
   const shippingBillingOptions = useSelector((state) => state.auth.shippingBillingOptions);
   const shippingOptionsLoader = useSelector((state) => state.auth.shippingOptionsLoader)
   const auth = useSelector((state) => state.auth)

   const [showShipStateCity, setShowShipStateCity] = useState(false);
   const [shipCityEdit, setShipCityEdit] = useState(false);
   const [shipPostalcodeEdit, setShipPostalcodeEdit] = useState(false);
   const [shipStateEdit, setShipStateEdit] = useState(false);
   const [shippingOptionSelected, setShippingOptionSelected] = useState(0);
   const [selectedShippingArredd, setSelectedShippingArredd] = useState({});
   const [choseOption, setChoseOption] = useState();

   useEffect(() => {
      if (userAddress && Object.keys(userAddress).length !== 0 && userAddress.lng) {
         setChoseOption(0)
      }
   }, [userAddress])

   useEffect(() => {
      if (userShippingAddressList?.length && userShippingAddressList[0].addressLine1) {
         setSelectedShippingArredd(userShippingAddressList[0]);
         setChoseOption(2)
      }
   }, [userShippingAddressList])

   useEffect(() => {
      if ((cart.storeType === 'Shopify' || 'SHOPIFY') && shippingOptions.data && shippingOptions.data.length > 0 && shippingOptions.data[0].rate_id != "") {
         setShippingOptionSelected(0);
         setShippingOptionSelect(shippingOptions.data[0]);
      }
   }, [shippingOptions.data]);

   const onChangeSetAddress = (address, type) => {
      handleChange('userShippingAddress', 'addressLine1', address);
   };

   const handleSelectAddress = async (value, addressType) => {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      onchangeAddressToGetInfo(latLng.lat, latLng.lng, addressType);
   };

   function onchangeAddressToGetInfo(a, b, addressType) {
      setShowShipStateCity(true);
      handleChange('userShippingAddress', 'lat', a);
      handleChange('userShippingAddress', 'lng', b);

      Geocode.fromLatLng(a, b).then(
         (response) => {
            const address = response.results[0].formatted_address
            handleChange(
               'userShippingAddress',
               'address',
               address
            );
            let city, state, country, address1, postal_code, country_code, state_code;
            for (let i = 0; i < response.results[0].address_components.length; i++) {
               for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                  switch (response.results[0].address_components[i].types[j]) {
                     case 'locality':
                        city = response.results[0].address_components[i].long_name;
                        setShipCityEdit(true);
                        handleChange(
                           'userShippingAddress',
                           'cityName',
                           response.results[0].address_components[i].long_name
                        );
                        break;
                     case 'administrative_area_level_1':
                        state = response.results[0].address_components[i].long_name;
                        state_code = response.results[0].address_components[i].short_name;
                        setShipStateEdit(true);
                        handleChange(
                           'userShippingAddress',
                           'state',
                           response.results[0].address_components[i].long_name
                        );
                        break;
                     case 'country':
                        country = response.results[0].address_components[i].long_name;
                        country_code = response.results[0].address_components[i].short_name;
                        handleChange(
                           'userShippingAddress',
                           'country',
                           response.results[0].address_components[i].long_name
                        );
                        break;
                     case 'street_number':
                        address1 = response.results[0].address_components[i].long_name;
                        handleChange(
                           'userShippingAddress',
                           'addressLine1',
                           response.results[0].address_components[i].long_name
                        );
                        break;
                     case 'route':
                        address1 += ' ' + response.results[0].address_components[i].long_name;
                        handleChange(
                           'userShippingAddress',
                           'addressLine1',
                           address1
                        );
                        break;
                     case 'postal_code':
                        postal_code = response.results[0].address_components[i].long_name;
                        setShipPostalcodeEdit(true);
                        handleChange(
                           'userShippingAddress',
                           'postalCode',
                           response.results[0].address_components[i].long_name
                        );
                        break;
                     default:
                  }
               }
            }
            if (cartProductsReducer.length > 0 && (cart.storeType === 'Shopify' || 'SHOPIFY')  ) {
               getShippingInfo(city, state_code, country_code, address1, postal_code);
            }
            // Get Shipping API's
         },
         (error) => {
            console.error(error);
         }
      );
   }

   // GET SHIPPING API's
   const getShippingInfo = (city, state_code, country_code, address1, postal_code) => {
      dispatch(shippingOptionLoader(true));
      getShipping({
         cartItemId: cartItemId,
         email: 'fullstackdeveloper918@gmail.com',
         ShippingAddress: {
            first_name: userInfo?.firstname ? userInfo?.firstname : 'Demo',
            last_name: userInfo?.lastname ? userInfo?.lastname : 'Demo',
            address1: `${address1 ? address1 : 'Demo'}`,
            city: `${city ? city : 'Demo'}`,
            province_code: `${state_code ? state_code : 'Demo'}`,
            country_code: `${country_code ? country_code : 'Demo'}`,
            phone: userInfo?.phoneNumber ? userInfo?.phoneNumber : `9826865386`,
            zip: `${postal_code ? postal_code : '123456'}`,
         },
      },
         {
            'apiKey': API_URL_FOUR.apiKey,
            'apiSecret': API_URL_FOUR.apiSecret,
         })
         .then(function (response) {
            if (response.status && response.data && (cart.storeType === 'Shopify' || 'SHOPIFY') ) {
               dispatch(addShippingOption(response.data.shipping_rates));
            }
         })
         .catch(function (error) {
            console.log('error', error);
         });
   };

   return (
      <>
         <h5 className="mb-3"> Shipping </h5>
         <div className="form-group login-radio-btn">
            {/* {payWithNewCard && <div> */}
            {/* {userInfo.userId && <label className="no-margin"> */}
            {/* {shippingOptions.data && shippingOptions.data.length > 0 && shippingOptions.data[0].rate_id != "" && <label className="no-margin"> */}
            {userAddress && Object.keys(userAddress).length && userAddress.postalCode && <label className="no-margin">
               <input type="radio" name="shipping-method" value="0" checked={shippingBillingOptions && choseOption === 0 ? true : false} onChange={() => {
                  setIsShipDiffrentAddress(false);
                  setChoseOption(0);
                  setSelectedShippingArredd({});
                  dispatch({ type: 'SHIPPING_BILLING_OPTIONS' });
                  if (cartProductsReducer.length > 0 && (cart.storeType === 'Shopify' || 'SHOPIFY')  ) {
                     getShippingInfo(userAddress.cityName, userAddress.state, userAddress.country, userAddress.addressLine1, userAddress.postalCode);
                  }
               }} />
               <span className='billing-address-text'>Ship to billing address</span>
            </label>}
            {/* </div>} */}
            {userShippingAddressList && userShippingAddressList.find(item => item.countryCode !== '') && (

               <>
                  {/* <h5 className="mb-3"> User Shipping Address </h5> */}
                  {loginType === 'Guest' || shippingTotal || (cart.storeType !== 'Shopify' || 'SHOPIFY')  && userShippingAddressList?.length ? userShippingAddressList.map((data, i) => (
                     <div key={i} className="mt-2 full-address">
                        <label>
                           <input type="radio" name="shipping-method" value={data.addressLine1} checked={!shippingBillingOptions && selectedShippingArredd.addressLine1 === data.addressLine1 ? true : false} onChange={() => {
                              setIsShipDiffrentAddress(false);
                              setChoseOption(2);
                              setSelectedShippingArredd(data);
                              dispatch({ type: 'SHIPPING_OTHER_OPTIONS' });
                              if (cartProductsReducer.length > 0 && (cart.storeType === 'Shopify' || 'SHOPIFY')  ) {
                                 getShippingInfo(data.city, data.state, data.countryName, data.addressLine1, data.postalCode);
                              }
                           }} />
                           <span className='address-text'>{userFullAddress(data)}</span>
                        </label>
                     </div>
                  )) : <Skeleton count={2} />}
               </>
            )}
            {loginType === 'Guest' || shippingTotal || (cart.storeType !== 'Shopify' || 'SHOPIFY')  ? <div className="mt-2">
               <label>
                  <input type="radio" name="shipping-method" value="1" checked={choseOption === 1 ? true : false} onChange={() => {
                     setIsShipDiffrentAddress(true);
                     setChoseOption(1);
                     setSelectedShippingArredd({});
                  }} />
                  <span className='address-text'>Ship to a different address</span>
               </label>
            </div> : <Skeleton />}
         </div>

         {/* USE NEW SHIP ADDRESS */}
         {isShipDiffrentAddress && (
            <>
               <div className="col-md-12 order-md-3">
                  <div className="row form-group">
                     <div className="col-md-12">
                        <label className="small-text">
                           <strong>Recipient's Name</strong> <span className="required">*</span>
                        </label>
                        <br />
                        <div
                           style={{
                              border: '1px solid #ced4da',
                              overflow: 'hidden',
                              borderRadius: '4px',
                           }}
                           className="validmonth"
                        >
                           <input
                              id="shippingName"
                              name="shippingName"
                              type="text"
                              placeholder="Recipient name"
                              className="form-control"
                              value={userInfo && userInfo.shippingName ? userInfo.shippingName : ''}
                              autoComplete="off"
                              onChange={(e) => {
                                 handleChange('userInfo', 'shippingName', e.target.value);
                              }}
                           />
                        </div>
                     </div>
                  </div>
                  <div className="row form-group pt-3">
                     <div className="col-md-12">
                        <label className="small-text">
                           {' '}
                           <strong>
                              Address line 1 <span className="required">*</span>
                           </strong>{' '}
                        </label>{' '}
                        <br />
                        <PlacesAutocomplete
                           searchOptions={{
                              componentRestrictions: {
                                 country: ['US', 'IN'],
                              },
                           }}
                           value={userShippingAddress && userShippingAddress.addressLine1 ? userShippingAddress.addressLine1 : ''}
                           id="addressLine1"
                           name="addressLine1"
                           className=""
                           onChange={(address) => {
                              handleChange('userShippingAddress', 'lat', '');
                              handleChange('userShippingAddress', 'lng', '');
                              handleChange('userShippingAddress', 'address', '');
                              handleChange('userShippingAddress', 'cityName', '');
                              handleChange('userShippingAddress', 'shipState', '');
                              handleChange('userShippingAddress', 'shipCountry', '');
                              handleChange('userShippingAddress', 'addressLine1', '');
                              handleChange('userShippingAddress', 'postalCode', '');
                              setShowShipStateCity(false);
                              setShipCityEdit(false);
                              setShipCityEdit(false);
                              setShipPostalcodeEdit(false);
                              onChangeSetAddress(address, 'shipAddress');
                           }}
                           onSelect={(value) => {
                              handleSelectAddress(value, 'shipAddress');
                           }}
                        >
                           {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div className="relative">
                                 <input
                                    id="shipstreetlist"
                                    className="form-control"
                                    {...getInputProps({
                                       placeholder: 'Eg. 123 Main Street',
                                    })}
                                 />
                                 <div className="pac-container address-container hdpi pac-logo">
                                    {loading ? <div>...loading</div> : null}
                                    {suggestions.map((suggestion, index) => {
                                       const className = suggestion.active ? 'pac-item1--active' : 'pac-item1';
                                       const style = {
                                          backgroundColor: suggestion.active ? '#3c78d8' : '#fff',
                                          padding: suggestion.active ? '5px 10px' : '5px 10px',
                                          color: suggestion.active ? '#000' : '',
                                          fontSize: suggestion.active ? '14px' : '14px',
                                       };
                                       return (
                                          <div
                                             {...getSuggestionItemProps(suggestion, {
                                                style,
                                                className,
                                             })}
                                             key={index}
                                             id="shipAddressErr"
                                          >
                                             {suggestion.description}
                                          </div>
                                       );
                                    })}
                                 </div>
                              </div>
                           )}
                        </PlacesAutocomplete>
                     </div>
                  </div>
                  <div className="row form-group pt-3">
                     <div className="col-md-12">
                        <label className="small-text">
                           {' '}
                           <strong>Address line 2</strong>{' '}
                        </label>{' '}
                        <br />
                        <input
                           type="text"
                           className="form-control"
                           id="addressLine2"
                           name="addressLine2"
                           placeholder = "Eg. Apt 123"
                           onChange={(e) => {
                              handleChange('userShippingAddress', 'addressLine2', e.target.value);
                           }}
                           value={userShippingAddress && userShippingAddress.addressLine2 ? userShippingAddress.addressLine2 : ''}
                        />
                     </div>
                  </div>
                  {/* {userShippingAddress && userShippingAddress.address && showShipStateCity && ( */}
                  <div className="row form-group pt-2">
                     <div className="col-md-12">
                        <label className="small-text">
                           {' '}
                           <strong>City</strong>{' '}
                        </label>{' '}
                        <br />
                        <input
                           type="text"
                           disabled={shipCityEdit ? true : false}
                           className="form-control"
                           placeholder="San Francisco"
                           onChange={(e) => {
                              handleChange('userShippingAddress', 'cityName', e.target.value);
                           }}
                           value={userShippingAddress && userShippingAddress.cityName ? userShippingAddress.cityName : ''}
                        />
                     </div>
                     <div className="form-group pt-3">
                        <div className="col-md-12">
                           <label className="small-text">
                              {' '}
                              <strong>State</strong>{' '}
                           </label>{' '}
                           <br />
                           <input
                              type="text"
                              disabled={shipStateEdit ? true : false}
                              className="form-control"
                              placeholder="California"
                              onChange={(e) => {
                                 handleChange('userShippingAddress', 'state', e.target.value);
                              }}
                              value={userShippingAddress && userShippingAddress.state ? userShippingAddress.state : ''}
                           />
                        </div>
                     </div>
                     <div className="form-group extra-margin pt-3">
                        <div className="col-md-12">
                           <label className="small-text">
                              {' '}
                              <strong>ZIP</strong>{' '}
                           </label>{' '}
                           <br />
                           <input
                              type="tel"
                              disabled={shipPostalcodeEdit ? true : false}
                              className="form-control "
                              placeholder="97123"
                              name="zip"
                              onChange={(e) => {
                                 handleChange('userShippingAddress', 'postalCode', e.target.value);
                              }}
                              value={userShippingAddress && userShippingAddress.postalCode ? userShippingAddress.postalCode : ''}
                           />
                        </div>
                     </div>
                  </div>
                  {/* )} */}
               </div>
            </>
         )}
         {/* USE USER SHIP ADDRESS */}
         {/* {selectedShippingArredd && Object.keys(selectedShippingArredd).length !== 0 && (
          <>
            <h5> Shipping </h5>
            <div className="col-md-12 order-md-3">
              <div className="row form-group">
                <div className="col-md-12">
                  <label className="small-text">
                    <strong>Recipient's Name</strong> <span className="required">*</span>
                  </label>
                  <br />
                  <div
                    style={{
                      border: '1px solid #ced4da',
                      overflow: 'hidden',
                      borderRadius: '4px',
                    }}
                    className="validmonth"
                  >
                    <input
                      id="shippingName"
                      name="shippingName"
                      type="text"
                      placeholder="Recipient name"
                      className="form-control"
                      value={userInfo && userInfo.shippingName ? userInfo.shippingName : ''}
                      autoComplete="off"
                      onChange={(e) => {
                        handleChange('userInfo', 'shippingName', e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-group pt-3">
                <div className="col-md-12">
                  <label className="small-text">
                    {' '}
                    <strong>
                      Address line 1 <span className="required">*</span>
                    </strong>{' '}
                  </label>{' '}
                  <br />
                  <input
                    type="text"
                    className="form-control"
                    id="addressLine2"
                    name="addressLine2"
                    readOnly
                    value={selectedShippingArredd.addressLine1}
                  />
                </div>
              </div>
              <div className="row form-group pt-3">
                <div className="col-md-12">
                  <label className="small-text">
                    {' '}
                    <strong>Address line 2</strong>{' '}
                  </label>{' '}
                  <br />
                  <input
                    type="text"
                    className="form-control"
                    id="addressLine2"
                    name="addressLine2"
                    readOnly
                    value={selectedShippingArredd.addressLine2}
                  />
                </div>
              </div>
              {/* {userShippingAddress && userShippingAddress.address && showShipStateCity && ( * /}
                <div className="row form-group pt-2">
                  <div className="col-md-12">
                    <label className="small-text">
                      {' '}
                      <strong>City</strong>{' '}
                    </label>{' '}
                    <br />
                    <input
                      type="text"
                      disabled={shipCityEdit ? true : false}
                      className="form-control"
                      placeholder=""
                      readOnly
                      value={selectedShippingArredd.city}
                    />
                  </div>
                  <div className="form-group pt-3">
                    <div className="col-md-12">
                      <label className="small-text">
                        {' '}
                        <strong>State</strong>{' '}
                      </label>{' '}
                      <br />
                      <input
                        type="text"
                        disabled={shipStateEdit ? true : false}
                        className="form-control"
                        placeholder=""
                        readOnly
                        value={selectedShippingArredd.state}
                      />
                    </div>
                  </div>
                  <div className="form-group extra-margin pt-3">
                    <div className="col-md-12">
                      <label className="small-text">
                        {' '}
                        <strong>ZIP</strong>{' '}
                      </label>{' '}
                      <br />
                      <input
                        type="tel"
                        disabled={shipPostalcodeEdit ? true : false}
                        className="form-control "
                        placeholder="ZIP"
                        name="zip"
                        readOnly
                        value={selectedShippingArredd.postalCode}
                      />
                    </div>
                  </div>
                </div>
              {/* )} * /}
            </div>
          </>
        )} */}
         {/* CALCULATING SHIPING OPTION */}
         {/* {userShippingAddressList && userShippingAddressList.find(item => item.countryCode !== '') && */}
       {(cart.storeType === 'Shopify' || 'SHOPIFY')  &&
         <>
            {shippingOptionsLoader ? <h5 className="mb-3"> Calculating Shipping </h5>
               : <h5 className="mb-3 mt-3"> Shipping options </h5>}
            <div className="form-group mb-4">
               {!shippingOptionsLoader && shippingOptions.data && shippingOptions.data.length > 0 && shippingOptions.data[0].rate_id != "" ? shippingOptions.data.map((data, i) => (
                  <div key={i}>
                     <label className="no-margin">
                        <input
                           type="radio"
                           name="shipping-option"
                           id="shipping-option"
                           checked={shippingOptionSelected === i ? true : false}
                           value={data.price}
                           onChange={() => {
                              setShippingOptionSelected(i);
                              setShippingOptionSelect(data);
                           }}
                        />
                        &nbsp; {data.title}
                        <span className="float-right">{formatMoney(data.price ? Number(data.price) : formatMoney("0.00"))}</span>
                     </label>
                  </div>
               )) : loginType !== 'Guest' && <Skeleton count={2} />}
            </div>
            <label className="small-text px-3 pb-1">{!shippingOptionsLoader && shippingOptions.data && shippingOptions.data.length > 0 && shippingOptions.data[0].rate_id != "" ? `Shipping instructions (optional)` : loginType !== 'Guest' && <Skeleton />}</label>
            {!shippingOptionsLoader && shippingOptions.data && shippingOptions.data.length > 0 && shippingOptions.data[0].rate_id != "" ?
               <textarea
                  id="shipping-instruction"
                  type="text"
                  className="form-control"
                  placeholder="Enter shipping instructions here"
                  name="shipping-instruction"
                  spellCheck="false"
                  onChange={(e) => { handleChange('userInfo', 'shipingNotes', e.target.value) }}
               ></textarea> : loginType !== 'Guest' && <Skeleton height={30} />}
         </>
         } 
      </>
   );
}

export default Shipping;
