import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Geocode from 'react-geocode';
import { useDispatch, useSelector } from 'react-redux';
import { addShippingOption, shippingOptionLoader } from '../../../redux/actions/authActions';
import { getShipping } from '../../../services/authServices';
import { useToasts } from 'react-toast-notifications';
import { API_URL_FOUR } from "../../../config/config";
import Skeleton from 'react-loading-skeleton';

Geocode.setApiKey('AIzaSyCs6mzbRW8QYOzNkPGWG9ghCYfOCT5vOIc');
Geocode.setLanguage('en');
Geocode.setRegion('es');
Geocode.setLocationType('ROOFTOP');
Geocode.enableDebug();

function Payment(props) {
   const { animateCSS,
      isShipDiffrentAddress,
      handleChange,
      onCardSelectionChanged,
      payWithNewCard,
      setPayWithNewCard,
      setCallBackCvv,
      shippingTotal,
      loginType
   } = props;
   const dispatch = useDispatch();
   const userInfo = useSelector((state) => state.auth.userInfo);
   const cartProductsReducer = useSelector((state) => state.auth.products);
   const cartItemId = useSelector((state) => state.auth.cart?.cartItemId);
   const userAddress = useSelector((state) => state.auth.userAddress);
   const userShippingAddress = useSelector((state) => state.auth.userShippingAddress);
   const userSaveCard = useSelector((state) => state.auth.userSaveCard);
   const merchantUrlData = useSelector((state) => state.auth.merchantUrlData);
   const merchantData = useSelector((state) => state.auth.merchantData);
   const cart = useSelector((state) => state.auth.cart);

   const [showStateCity, setShowStateCity] = useState(false);
   const [billingCityEdit, setBillingCityEdit] = useState(false);
   const [billingStateEdit, setBillingStateEdit] = useState(false);
   const [billingPostalcodeEdit, setBillingPostalcodeEdit] = useState(false);
   const { addToast } = useToasts();
   const [showShipStateCity, setShowShipStateCity] = useState(false);
   const [shipCityEdit, setShipCityEdit] = useState(false);
   const [shipStateEdit, setShipStateEdit] = useState(false);
   const [shipPostalcodeEdit, setShipPostalcodeEdit] = useState(false);
   const [month, setMonth] = React.useState('');
   const [year, setYear] = React.useState('');
   const errorlist = {
      errorMonth: "",
      errorYear: "",
      errorCvv: "",
      errorName: "",
   }
   const [cvv, setCvv] = useState();
   const [errorList, setErrorList] = useState(errorlist);

   const onChangeSetAddress = (address, type) => {
      if (type === 'billingAddress') {
         // setAddress(address);
         handleChange('userAddress', 'addressLine1', address);
      } else {
         // setShipAddress(address);
         handleChange('userShippingAddress', 'addressLine1', address);
      }
   };

   // GET SHIPPING API's
   const getShippingInfo = (city, state, country, address1, postal_code) => {
      dispatch(shippingOptionLoader(true));
      getShipping({
         cartItemId: cartItemId,
         email: 'fullstackdeveloper918@gmail.com',
         ShippingAddress: {
            first_name: userInfo?.firstname ? userInfo?.firstname : 'Demo',
            last_name: userInfo?.lastname ? userInfo?.lastname : 'Demo',
            address1: `${address1 ? address1 : 'Demo'}`,
            city: `${city ? city : 'Demo'}`,
            province_code: `${state ? state : 'Demo'}`,
            country_code: `${country ? country : 'Demo'}`,
            phone: userInfo?.phoneNumber ? userInfo?.phoneNumber : `9826865386`,
            zip: `${postal_code ? postal_code : '123456'}`,
         },
      },
         {
            'apiKey': merchantData ? `${merchantData?.storeApiKey}`: API_URL_FOUR.apiKey,
            'apiSecret': merchantData ? `${merchantData?.storeSecretKey}`: API_URL_FOUR.apiSecret,
         })
         .then(function (response) {
            if (response.status && response.data) {
               dispatch(addShippingOption(response.data.shipping_rates));
               dispatch({ type: 'SHIPPING_BILLING_OPTIONS' });
            }
         })
         .catch(function (error) {
            console.log('error', error);
         });
   };

   const handleSelectAddress = async (value, addressType) => {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      onchangeAddressToGetInfo(latLng.lat, latLng.lng, addressType);
   };

   function onchangeAddressToGetInfo(a, b, addressType) {
      if (addressType === 'billingAddress') {
         setShowStateCity(true);
         handleChange('userAddress', 'lat', a);
         handleChange('userAddress', 'lng', b);
      } else {
         setShowShipStateCity(true);
         handleChange('userShippingAddress', 'lat', a);
         handleChange('userShippingAddress', 'lng', b);
      }
      Geocode.fromLatLng(a, b).then(
         (response) => {
            const address = response.results[0].formatted_address
            handleChange(
               addressType === 'billingAddress' ? 'userAddress' : 'userShippingAddress',
               'address',
               address
            );
            let city, state, country, address1, postal_code, country_code, state_code;
            for (let i = 0; i < response.results[0].address_components.length; i++) {
               for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                  switch (response.results[0].address_components[i].types[j]) {
                     case 'locality':
                        city = response.results[0].address_components[i].long_name;
                        addressType === 'billingAddress' ? setBillingCityEdit(true) : setShipCityEdit(true);
                        handleChange(
                           addressType === 'billingAddress' ? 'userAddress' : 'userShippingAddress',
                           addressType === 'billingAddress' ? 'cityName' : 'cityName',
                           response.results[0].address_components[i].long_name
                        );
                        break;
                     case 'administrative_area_level_1':
                        state = response.results[0].address_components[i].long_name;
                        state_code = response.results[0].address_components[i].short_name;
                        addressType === 'billingAddress' ? setBillingStateEdit(true) : setShipStateEdit(true);
                        handleChange(
                           addressType === 'billingAddress' ? 'userAddress' : 'userShippingAddress',
                           addressType === 'billingAddress' ? 'state' : 'state',
                           response.results[0].address_components[i].long_name
                        );
                        break;
                     case 'country':
                        country = response.results[0].address_components[i].long_name;
                        country_code = response.results[0].address_components[i].short_name;
                        handleChange(
                           addressType === 'billingAddress' ? 'userAddress' : 'userShippingAddress',
                           addressType === 'billingAddress' ? 'country' : 'country',
                           response.results[0].address_components[i].long_name
                        );
                        break;
                     case 'street_number':
                        address1 = response.results[0].address_components[i].long_name;
                        handleChange(
                           addressType === 'billingAddress' ? 'userAddress' : 'userShippingAddress',
                           addressType === 'billingAddress' ? 'addressLine1' : 'addressLine1',
                           response.results[0].address_components[i].long_name
                        );
                        break;
                     case 'route':
                        address1 += ' ' + response.results[0].address_components[i].long_name;
                        handleChange(
                           addressType === 'billingAddress' ? 'userAddress' : 'userShippingAddress',
                           addressType === 'billingAddress' ? 'addressLine1' : 'addressLine1',
                           address1
                        );
                        break;
                     case 'postal_code':
                        postal_code = response.results[0].address_components[i].long_name;
                        addressType === 'billingAddress' ? setBillingPostalcodeEdit(true) : setShipPostalcodeEdit(true);
                        handleChange(
                           addressType === 'billingAddress' ? 'userAddress' : 'userShippingAddress',
                           addressType === 'billingAddress' ? 'postalCode' : 'postalCode',
                           response.results[0].address_components[i].long_name
                        );
                        break;
                     default:
                  }
               }
            }
            if (cartProductsReducer.length > 0) {
               getShippingInfo(city, state_code, country_code, address1, postal_code);
            }
            // Get Shipping API's
         },
         (error) => {
            console.error(error);
         }
      );
   }

   const onChangeMonth = (e) => {
      const checkDate = new Date();
      const currentMonth = checkDate.getMonth() + 1;
      checkDate.setMonth(checkDate.getMonth() - 1);
      const previousMonth = checkDate.toLocaleString('default', {
         month: 'short',
      });
      const currentYear = checkDate.getFullYear();
      var twoDigitYear = currentYear.toString().substr(-2);
      const regex_month = /^[0-9\b]+$/;

      let value = e.target.value;
      if (!regex_month.test(value)) {
         value = '';
      }
      if (parseInt(value) >= 13) {
         value = value[0];
         animateCSS('month', 'shakeX');
         setErrorList({ ...errorList, errorMonth: "red" })
      } else {
         setErrorList({ ...errorList, errorMonth: "black" })
      }
      if ((value.length === 1 && value !== "1") && (value.length === 1 && value !== "0")) {
         value = ("0" + value)
      }
      if (value.length === 2 && value === "00") {
         value = '0';
      }
      if (value.length === 2) {
         document.getElementById('year').focus();
      }
      if (value === twoDigitYear) {
         if (value === currentMonth && value !== previousMonth) {
            setErrorList({ ...errorList, errorMonth: "black" })
         } else {
            setErrorList({ ...errorList, errorMonth: "red" })
         }
      }
      if (value === '' || value === '0') {
         setYear('');
      }
      setMonth(value);
      handleChange('userInfo', 'cardMonth', value);
      setYear('');
   };

   const onChangeYear = (e) => {
      const regex_year = /^[0-9\b]+$/;
      let value = e.target.value;
      const checkDate = new Date();
      const currentMonth = checkDate.getMonth() + 1;
      const currentYear = checkDate.getFullYear();
      checkDate.setMonth(checkDate.getMonth() - 1);
      // const previousMonth = checkDate.toLocaleString('default', {
      //   month: 'short',
      // });
      var twoDigitYear = currentYear.toString().substr(-2);
      if (!regex_year.test(value)) {
         value = '';
      }
      if (value.length === 2 && value < twoDigitYear) {
         animateCSS('year', 'shakeX');
         setErrorList({ ...errorList, errorYear: "red" })
      } else {
         if (value.length > 1) {
         }
         if (value === twoDigitYear) {
            if (month < currentMonth) {
               animateCSS('month', 'shakeX');
               animateCSS("year", "shakeX")
               setErrorList({ ...errorList, errorMonth: "red" })
            } else {
               setErrorList({
                  ...errorList,
                  errorMonth: "black",
                  errorYear: "black",
               })
               // window.Spreedly.transferFocus('cvv');
               document.getElementById("spreedly-cvv").focus()
            }
         } else {
            setErrorList({
               ...errorList,
               errorMonth: "black",
               errorYear: "black",
            })
         }
         if (value === '') {
            setMonth('');
            document.getElementById('month').focus();
         } else if (value.length === 2) {
            // window.Spreedly.transferFocus('spreedly-cvv');
            document.getElementById("spreedly-cvv").focus()
         }
         setYear(value);
         handleChange('userInfo', 'cardYear', value);
      }
   };

   useEffect(() => {
      if (userAddress && userAddress.addressLine1 && userAddress.cityName) {
         setShowStateCity(true);
         if (userAddress.cityName) {
            setBillingCityEdit(true);
         }
         if (userAddress.state) {
            setBillingStateEdit(true);
         }
         if (userAddress.postalCode) {
            setBillingPostalcodeEdit(true);
         }
      }
   }, [userAddress]);

   useEffect(() => {
      if (userSaveCard && userSaveCard.length > 0) {
         const defaultSavedCardData = userSaveCard.find((val) => val.defaultSource);
         onCardSelectionChanged(defaultSavedCardData);
         setPayWithNewCard(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userSaveCard]);

   const passChar = e => {
      setCvv([...e.target.value].reduce((input, char, i) => `${input}${(i >= 0) ? '*' : char}`, ''))
      setCallBackCvv([...e.target.value].reduce((input, char, i) => `${input}${(i >= 0) ? '*' : char}`, ''))
   };

   return (
      <>
         <h5 className="mb-3"> Payment </h5>
         {loginType === 'Guest' || shippingTotal || (cart.storeType !== 'Shopify' || 'SHOPIFY')  ?
            !payWithNewCard &&
            <>
               {userSaveCard?.map(
                  (savedCardData, index) => {
                     return (
                        <label
                           className="text-capitalize"
                           key={index}
                        >
                           <input
                              type='radio'
                              value={savedCardData}
                              name='card-option'
                              onChange={() => { onCardSelectionChanged(savedCardData); }}
                              defaultChecked={
                                 savedCardData?.defaultSource
                                    ? true
                                    : ""
                              }
                              style={{
                                 marginBottom: "10px",
                              }}
                           />
                           &nbsp;{" "}
                           {savedCardData.cardType === 'american_express' ? 'amex' : savedCardData.cardType}
                           &nbsp;{" "}
                           <span className="text-lowercase">{"ending".toLowerCase()}</span>
                           &nbsp;{" "}
                           {savedCardData.cardDisplay.substr(15)}{" "}
                        </label>
                     )
                  }
               )}
               {/* {isShipDiffrentAddress && (
                  <>
                     <h5> Shipping </h5>
                     <div className="col-md-12 order-md-3">
                        <div className="row form-group">
                           <div className="col-md-12">
                              <label className="small-text">
                                 <strong>Recipient's Name</strong> <span className="required">*</span>
                              </label>
                              <br />
                              <div
                                 style={{
                                    border: '1px solid #ced4da',
                                    overflow: 'hidden',
                                    borderRadius: '4px',
                                 }}
                                 className="validmonth"
                              >
                                 <input
                                    id="shippingName"
                                    name="shippingName"
                                    type="text"
                                    placeholder="Recipient name"
                                    className="form-control"
                                    value={userInfo && userInfo?.shippingName ? userInfo.shippingName : ''}
                                    autoComplete="off"
                                    onChange={(e) => {
                                       handleChange('userInfo', 'shippingName', e.target.value);
                                    }}
                                 />
                              </div>
                           </div>
                        </div>
                        <div className="row form-group pt-3">
                           <div className="col-md-12">
                              <label className="small-text">
                                 {' '}
                                 <strong>
                                    Address line 1 <span className="required">*</span>
                                 </strong>{' '}
                              </label>{' '}
                              <br />
                              <PlacesAutocomplete
                                 searchOptions={{
                                    componentRestrictions: {
                                       country: ['US', 'IN'],
                                    },
                                 }}
                                 value={userShippingAddress && userShippingAddress.addressLine1 ? userShippingAddress.addressLine1 : ''}
                                 id="addressLine1"
                                 name="addressLine1"
                                 className=""
                                 onChange={(address) => {
                                    handleChange('userShippingAddress', 'lat', '');
                                    handleChange('userShippingAddress', 'lng', '');
                                    handleChange('userShippingAddress', 'address', '');
                                    handleChange('userShippingAddress', 'cityName', '');
                                    handleChange('userShippingAddress', 'shipState', '');
                                    handleChange('userShippingAddress', 'shipCountry', '');
                                    handleChange('userShippingAddress', 'addressLine1', '');
                                    handleChange('userShippingAddress', 'postalCode', '');
                                    setShowShipStateCity(false);
                                    setShipCityEdit(false);
                                    setShipCityEdit(false);
                                    setShipPostalcodeEdit(false);
                                    onChangeSetAddress(address, 'shipAddress');
                                 }}
                                 onSelect={(value) => {
                                    handleSelectAddress(value, 'shipAddress');
                                 }}
                              >
                                 {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div className="relative">
                                       <input
                                          id="shipstreetlist"
                                          className="form-control"
                                          {...getInputProps({
                                             placeholder: 'Street',
                                          })}
                                       />
                                       <div className="pac-container address-container hdpi pac-logo">
                                          {loading ? <div>...loading</div> : null}
                                          {suggestions.map((suggestion, index) => {
                                             const className = suggestion.active ? 'pac-item1--active' : 'pac-item1';
                                             const style = {
                                                backgroundColor: suggestion.active ? '#3c78d8' : '#fff',
                                                padding: suggestion.active ? '5px 10px' : '5px 10px',
                                                color: suggestion.active ? '#000' : '',
                                                fontSize: suggestion.active ? '14px' : '14px',
                                             };
                                             return (
                                                <div
                                                   {...getSuggestionItemProps(suggestion, {
                                                      style,
                                                      className,
                                                   })}
                                                   key={index}
                                                   id="shipAddressErr"
                                                >
                                                   {suggestion.description}
                                                </div>
                                             );
                                          })}
                                       </div>
                                    </div>
                                 )}
                              </PlacesAutocomplete>
                           </div>
                        </div>
                        <div className="row form-group pt-3">
                           <div className="col-md-12">
                              <label className="small-text">
                                 {' '}
                                 <strong>Address line 2</strong>{' '}
                              </label>{' '}
                              <br />
                              <input
                                 type="text"
                                 className="form-control"
                                 id="addressLine2"
                                 name="addressLine2"
                                 onChange={(e) => {
                                    handleChange('userShippingAddress', 'addressLine2', e.target.value);
                                 }}
                                 value={userShippingAddress && userShippingAddress.addressLine2 ? userShippingAddress.addressLine2 : ''}
                              />
                           </div>
                        </div>
                        {userShippingAddress && userShippingAddress.address && showShipStateCity && ( 
                        <div className="row form-group pt-2">
                           <div className="col-md-12">
                              <label className="small-text">
                                 {' '}
                                 <strong>City</strong>{' '}
                              </label>{' '}
                              <br />
                              <input
                                 type="text"
                                 disabled={shipCityEdit ? true : false}
                                 className="form-control"
                                 placeholder=""
                                 onChange={(e) => {
                                    handleChange('userShippingAddress', 'cityName', e.target.value);
                                 }}
                                 value={userShippingAddress && userShippingAddress.cityName ? userShippingAddress.cityName : ''}
                              />
                           </div>
                           <div className="form-group pt-3">
                              <div className="col-md-12">
                                 <label className="small-text">
                                    {' '}
                                    <strong>State</strong>{' '}
                                 </label>{' '}
                                 <br />
                                 <input
                                    type="text"
                                    disabled={shipStateEdit ? true : false}
                                    className="form-control"
                                    placeholder=""
                                    onChange={(e) => {
                                       handleChange('userShippingAddress', 'state', e.target.value);
                                    }}
                                    value={userShippingAddress && userShippingAddress.state ? userShippingAddress.state : ''}
                                 />
                              </div>
                           </div>
                           <div className="form-group extra-margin pt-3">
                              <div className="col-md-12">
                                 <label className="small-text">
                                    {' '}
                                    <strong>ZIP</strong>{' '}
                                 </label>{' '}
                                 <br />
                                 <input
                                    type="tel"
                                    disabled={shipPostalcodeEdit ? true : false}
                                    className="form-control "
                                    placeholder="ZIP"
                                    name="zip"
                                    onChange={(e) => {
                                       handleChange('userShippingAddress', 'postalCode', e.target.value);
                                    }}
                                    value={userShippingAddress && userShippingAddress.postalCode ? userShippingAddress.postalCode : ''}
                                 />
                              </div>
                           </div>
                        </div>
                     )}
                     </div>
                  </>
               )} */}
            </> :
            <Skeleton count={3} />}
         <div className="show-hide-new-old-cart">
            {loginType === 'Guest' || shippingTotal || (cart.storeType !== 'Shopify' || 'SHOPIFY') ?
               !payWithNewCard &&
               <button
                  type='button'
                  className='btn-custom btn-default'
                  onClick={() => setPayWithNewCard(true)}
               >
                  Pay with a new card
               </button> :
               <Skeleton height={50} />}
         </div>
         <div className={`${!payWithNewCard ? 'DIVhide' : ''}`}>
            <div className={`col-md-12 order-md-3`}>
               <div>
                  <div className="row mb-0 form-group" id="PaymentFormNew">
                     <div className="col-md-12">
                        <input type="hidden" name="payment_method_token" id="payment_method_token" />
                        <div className="row form-group">
                           <div className="col-md-12">
                              <label className="small-text">
                                 {' '}
                                 <strong>Card number</strong> <span className="required">*</span>
                              </label>{' '}
                              <br />
                              <div className="card-img">
                                 <span id="cardLogo">
                                    <img id="cardLogoSet" src="https://wave-admin.s3.amazonaws.com/creditcard.svg" alt="" width="30px" height="30px" />
                                 </span>
                                 <div
                                    style={{
                                       border: '1px solid #ced4da',
                                       overflow: 'hidden',
                                       borderRadius: '4px',
                                    }}
                                 >
                                    <div
                                       id="spreedly-number"
                                       className="form-control"
                                       maxLength="18"
                                       style={{
                                          border: 'none',
                                       }}
                                    ></div>
                                 </div>
                                 <span className="required" id="cardNumberError"></span>
                              </div>
                           </div>
                        </div>
                        <div className="row pt-3">
                           <div className="col  form-group">
                              <label className="small-text">
                                 {' '}
                                 <strong>Month</strong> <span className="required">*</span>
                              </label>{' '}
                              <br />
                              <div
                                 style={{
                                    overflow: 'hidden',
                                    borderRadius: '4px',
                                    fontSize: '14px'
                                 }}
                                 className="validmonth"
                              >
                                 <input
                                    id="month"
                                    name="month"
                                    maxLength={2}
                                    value={userInfo?.cardMonth}
                                    onChange={onChangeMonth}
                                    onBlur={(e) => {
                                       if (e.target.value.length === 1 && e.target.value !== '0') {
                                          setMonth(`0${e.target.value}`);
                                       }
                                       if (e.target.value.length === 1 && e.target.value === '0') {
                                          setMonth(`${e.target.value}1`);
                                       }
                                    }}
                                    placeholder="MM"
                                    className="form-control"
                                    style={{
                                       color: errorList.errorMonth,
                                       textAlign: 'center',
                                       fontSize: '14px'
                                    }}
                                    autoComplete="on"
                                 />
                              </div>
                           </div>
                           <div className="col  form-group ">
                              <label className="small-text">
                                 {' '}
                                 <strong>Year</strong> <span className="required">*</span>
                              </label>{' '}
                              <br />
                              <div
                                 style={{
                                    overflow: 'hidden',
                                    borderRadius: '4px',
                                 }}
                                 className="validmonth"
                              >
                                 <input
                                    id="year"
                                    name="year"
                                    maxLength={2}
                                    value={userInfo?.cardYear}
                                    onKeyDown={(e) => {
                                       if (e.keyCode === 9) {
                                          e.preventDefault();
                                          return false;
                                       }
                                    }}
                                    onChange={onChangeYear}
                                    style={
                                       {
                                          color: errorList.errorYear,
                                          fontSize: '14px'
                                       }
                                    }
                                    placeholder="YY"
                                    className="form-control text-center"
                                    autoComplete="on"
                                 />
                              </div>
                           </div>
                           <div className="col form-group">
                              <label className="small-text">
                                 {' '}
                                 <strong>CVV</strong> <span className="required">*</span>
                              </label>{' '}
                              <br />
                              <div
                                 style={{
                                    overflow: 'hidden',
                                    borderRadius: '4px',
                                 }}
                                 className="validmonth"
                              >
                                 <input
                                    id="spreedly-cvv"
                                    type="text"
                                    className="form-control"
                                    placeholder='CVV'
                                    maxLength={4}
                                    // style={{
                                    //    color: 'red',
                                    // }}
                                    value={cvv}
                                    onChange={passChar}
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row form-group pt-3">
                  <div className="col-md-12">
                     <label className="small-text">
                        <strong>Name</strong> <span className="required">*</span>
                     </label>
                     <br />
                     <div
                        style={{
                           overflow: 'hidden',
                           borderRadius: '4px',
                        }}
                        className="validmonth"
                     >
                        <input
                           id="full_name"
                           name="full_name"
                           type="text"
                           placeholder="Eg. Alice Smith"
                           className="form-control"
                           autoComplete="on"
                           value={userInfo && userInfo.name ? userInfo.name : ''}
                           onChange={(e) => {
                              handleChange('userInfo', 'name', e.target.value);
                           }}
                        />
                     </div>
                  </div>
               </div>
               <div className="row form-group pt-3">
                  <div className="col-md-12">
                     <label className="small-text">
                        {' '}
                        <strong>
                           Address line 1 <span className="required">*</span>
                        </strong>{' '}
                     </label>{' '}
                     <br />
                     <PlacesAutocomplete
                        searchOptions={{
                           componentRestrictions: {
                              country: ['US', 'IN'],
                           },
                        }}
                        value={userAddress && userAddress.addressLine1 ? userAddress.addressLine1 : ''}
                        id="addressLine1"
                        name="addressLine1"
                        className=""
                        onChange={(address) => {
                           handleChange('userAddress', 'lat', '');
                           handleChange('userAddress', 'lng', '');
                           handleChange('userAddress', 'address', '');
                           handleChange('userAddress', 'cityName', '');
                           handleChange('userAddress', 'state', '');
                           handleChange('userAddress', 'country', '');
                           handleChange('userAddress', 'addressLine1', '');
                           handleChange('userAddress', 'postalCode', '');
                           setShowStateCity(false);
                           setBillingCityEdit(false);
                           setBillingStateEdit(false);
                           setBillingPostalcodeEdit(false);
                           onChangeSetAddress(address, 'billingAddress');
                           handleChange('userAddress', 'billingAddress', address);
                        }}
                        onSelect={(value) => {
                           handleSelectAddress(value, 'billingAddress');
                        }}
                     >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                           <div className="relative">
                              <input
                                 id="streetlist"
                                 className="form-control"
                                 {...getInputProps({
                                    placeholder: 'Eg. 123 Main Street',
                                 })}
                              />
                              <div className="pac-container address-container hdpi pac-logo">
                                 {loading ? <div>...loading</div> : null}
                                 {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active ? 'pac-item1--active' : 'pac-item1';
                                    const style = {
                                       backgroundColor: suggestion.active ? '#3c78d8' : '#fff',
                                       padding: suggestion.active ? '5px 10px' : '5px 10px',
                                       color: suggestion.active ? '#000' : '',
                                       fontSize: suggestion.active ? '14px' : '14px',
                                    };
                                    return (
                                       <div
                                          {...getSuggestionItemProps(suggestion, {
                                             style,
                                             className,
                                          })}
                                          key={index}
                                          id="addressErr"
                                       >
                                          {suggestion.description}
                                       </div>
                                    );
                                 })}
                              </div>
                           </div>
                        )}
                     </PlacesAutocomplete>
                  </div>
               </div>
               <div className="row form-group pt-3">
                  <div className="col-md-12">
                     <label className="small-text">
                        {' '}
                        <strong>Address line 2</strong>{' '}
                     </label>{' '}
                     <br />
                     <input
                        type="text"
                        className="form-control"
                        id="addressLine2"
                        name="addressLine2"
                        placeholder='Eg. Apt 123 '
                        onChange={(e) => {
                           handleChange('userAddress', 'addressLine2', e.target.value);
                        }}
                        value={userAddress && userAddress.addressLine2 ? userAddress.addressLine2 : ''}
                     />
                  </div>
               </div>
               {/* {showStateCity && ( */}
               <div className="row form-group pt-2">
                  <div className="col-md-12">
                     <label className="small-text">
                        {' '}
                        <strong>City</strong>{' '}
                     </label>{' '}
                     <br />
                     <input
                        type="text"
                        disabled={billingCityEdit ? true : false}
                        className="form-control"
                        placeholder="San Francisco"
                        onChange={(e) => {
                           handleChange('userAddress', 'city', e.target.value);
                        }}
                        value={userAddress && userAddress.cityName ? userAddress.cityName : ''}
                     />
                  </div>
                  <div className="form-group pt-3">
                     <div className="col-md-12">
                        <label className="small-text">
                           {' '}
                           <strong>State</strong>{' '}
                        </label>{' '}
                        <br />
                        <input
                           type="text"
                           disabled={billingStateEdit ? true : false}
                           className="form-control"
                           placeholder="California"
                           onChange={(e) => {
                              handleChange('userAddress', 'state', e.target.value);
                           }}
                           value={userAddress && userAddress.state ? userAddress.state : ''}
                        />
                     </div>
                  </div>
                  <div className="form-group extra-margin pt-3">
                     <div className="col-md-12">
                        <label className="small-text">
                           {' '}
                           <strong>ZIP</strong>{' '}
                        </label>{' '}
                        <br />
                        <input
                           type="tel"
                           disabled={billingPostalcodeEdit ? true : false}
                           className="form-control "
                           placeholder="97123"
                           name="zip"
                           onChange={(e) => {
                              handleChange('userAddress', 'postalCode', e.target.value);
                           }}
                           value={userAddress && userAddress.postalCode ? userAddress.postalCode : ''}
                        />
                     </div>
                  </div>
               </div>
               {/* )} */}
            </div>
            {/* {isShipDiffrentAddress && (
            <>
              <h5> Shipping </h5>
              <div className="col-md-12 order-md-3">
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="small-text">
                      <strong>Recipient's Name</strong> <span className="required">*</span>
                    </label>
                    <br />
                    <div
                      style={{
                        border: '1px solid #ced4da',
                        overflow: 'hidden',
                        borderRadius: '4px',
                      }}
                      className="validmonth"
                    >
                      <input
                        id="shippingName"
                        name="shippingName"
                        type="text"
                        placeholder="Recipient name"
                        className="form-control"
                        value={userInfo && userInfo.shippingName ? userInfo.shippingName : ''}
                        autoComplete="off"
                        onChange={(e) => {
                          handleChange('userInfo', 'shippingName', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-group pt-3">
                  <div className="col-md-12">
                    <label className="small-text">
                      {' '}
                      <strong>
                        Address line 1 <span className="required">*</span>
                      </strong>{' '}
                    </label>{' '}
                    <br />
                    <PlacesAutocomplete
                        searchOptions={{
                          componentRestrictions: {
                            country: ['US', 'IN'],
                          },
                        }}
                        value={userShippingAddress && userShippingAddress.addressLine1 ? userShippingAddress.addressLine1 : ''}
                        id="addressLine1"
                        name="addressLine1"
                        className=""
                        onChange={(address) => {
                          handleChange('userShippingAddress', 'lat', '');
                          handleChange('userShippingAddress', 'lng', '');
                          handleChange('userShippingAddress', 'address', '');
                          handleChange('userShippingAddress', 'cityName', '');
                          handleChange('userShippingAddress', 'shipState', '');
                          handleChange('userShippingAddress', 'shipCountry', '');
                          handleChange('userShippingAddress', 'addressLine1', '');
                          handleChange('userShippingAddress', 'postalCode', '');
                          setShowShipStateCity(false);
                          setShipCityEdit(false);
                          setShipCityEdit(false);
                          setShipPostalcodeEdit(false);
                          onChangeSetAddress(address, 'shipAddress');
                        }}
                        onSelect={(value) => {
                          handleSelectAddress(value, 'shipAddress');
                        }}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div className="relative">
                            <input
                              id="shipstreetlist"
                              className="form-control"
                              {...getInputProps({
                                placeholder: 'Street',
                              })}
                            />
                            <div className="pac-container address-container hdpi pac-logo">
                              {loading ? <div>...loading</div> : null}
                              {suggestions.map((suggestion, index) => {
                                const className = suggestion.active ? 'pac-item1--active' : 'pac-item1';
                                const style = {
                                  backgroundColor: suggestion.active ? '#3c78d8' : '#fff',
                                  padding: suggestion.active ? '5px 10px' : '5px 10px',
                                  color: suggestion.active ? '#000' : '',
                                  fontSize: suggestion.active ? '14px' : '14px',
                                };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                      className,
                                    })}
                                    key={index}
                                    id="shipAddressErr"
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                  </div>
                </div>
                <div className="row form-group pt-3">
                  <div className="col-md-12">
                    <label className="small-text">
                      {' '}
                      <strong>Address line 2</strong>{' '}
                    </label>{' '}
                    <br />
                    <input
                      type="text"
                      className="form-control"
                      id="addressLine2"
                      name="addressLine2"
                      onChange={(e) => {
                        handleChange('userShippingAddress', 'addressLine2', e.target.value);
                      }}
                      value={userShippingAddress && userShippingAddress.addressLine2 ? userShippingAddress.addressLine2 : ''}
                    />
                  </div>
                </div>
                {/* {userShippingAddress && userShippingAddress.address && showShipStateCity && ( * /}
                  <div className="row form-group pt-2">
                    <div className="col-md-12">
                      <label className="small-text">
                        {' '}
                        <strong>City</strong>{' '}
                      </label>{' '}
                      <br />
                      <input
                        type="text"
                        disabled={shipCityEdit ? true : false}
                        className="form-control"
                        placeholder=""
                        onChange={(e) => {
                          handleChange('userShippingAddress', 'cityName', e.target.value);
                        }}
                        value={userShippingAddress && userShippingAddress.cityName ? userShippingAddress.cityName : ''}
                      />
                    </div>
                    <div className="form-group pt-3">
                      <div className="col-md-12">
                        <label className="small-text">
                          {' '}
                          <strong>State</strong>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type="text"
                          disabled={shipStateEdit ? true : false}
                          className="form-control"
                          placeholder=""
                          onChange={(e) => {
                            handleChange('userShippingAddress', 'state', e.target.value);
                          }}
                          value={userShippingAddress && userShippingAddress.state ? userShippingAddress.state : ''}
                        />
                      </div>
                    </div>
                    <div className="form-group extra-margin pt-3">
                      <div className="col-md-12">
                        <label className="small-text">
                          {' '}
                          <strong>ZIP</strong>{' '}
                        </label>{' '}
                        <br />
                        <input
                          type="tel"
                          disabled={shipPostalcodeEdit ? true : false}
                          className="form-control "
                          placeholder="ZIP"
                          name="zip"
                          onChange={(e) => {
                            handleChange('userShippingAddress', 'postalCode', e.target.value);
                          }}
                          value={userShippingAddress && userShippingAddress.postalCode ? userShippingAddress.postalCode : ''}
                        />
                      </div>
                    </div>
                  </div>
                {/* )} * /}
              </div>
            </>
          )} */}
            {payWithNewCard && (
               <div className="show-hide-new-old-cart">
                  {localStorage.getItem("sessionToken") &&
                     userSaveCard && userSaveCard.length > 0 && (
                        <button
                           type='button'
                           className='btn-custom btn-default'
                           onClick={(e) => {setPayWithNewCard(false); window.scrollTo({top: 0, behavior: 'smooth'})}}
                        >
                           Use saved card
                        </button>
                     )}
               </div>
            )}
         </div>
      </>
   );
}

export default Payment;
